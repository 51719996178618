ion-content ion-toolbar {
  --background: translucent;
}
.container {
  --background: #2B9348;
  --background: -webkit-linear-gradient(
    to right,
    #007F5F,
    #2B9348
  );
  --background: linear-gradient(
    to right,
    #007F5F,
    #2B9348
  );
}

.fix-width-card{
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
