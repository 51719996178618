#profileBackground{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #8bc34a;
}
#profileContainer{
    background-color: white;
    position: absolute;
    top: 100px;
    width: 100%;
    border-radius: 25px 25px 0 0;
    padding-bottom: 10px;
}
#profileContainer .top{
    text-align: center;
    height: 60px;
    margin-bottom: 10px;
}
#profileContainer .top img{
    position: relative;
    width: 150px;    
    bottom: calc(50% + 10px);
    border-radius: 100%;
}
.center{
    text-align: center;
}

#sendRequest {
    width: 100%;
    max-width: 500px;
    border-radius: 50px;
    margin: auto;
    display: block;
}

#profileCardContainer {
    height: 300px;
    overflow: scroll hidden;
    margin: 10px 0;
    margin-bottom: 10px;
    white-space: nowrap;
}
.profileCard {
    color: black;
    cursor: pointer;
    width: 250px;
    display: inline-block;
    white-space: normal;    
    background: #d9f5b9;
}
#profileCardContainer::-webkit-scrollbar-thumb {
    background: #bbb;
}
#profileCardContainer::-webkit-scrollbar {
    height: 6px;
}
#profileCardContainer ion-card-content p {  
    height: 187px;
    overflow: hidden;
}