.map-container {
    width: 100%;
    position: fixed!important;
    top: 0;
    left: 0;
    height: calc(100% + 25px);
    z-index: -1;
}

#contentForMenu {
  padding: 16px;
  pointer-events: none;
}

#locationsSections{
    padding-top: calc(100vh - 440px);
}

#topSection ion-segment{
    background: rgba(255, 255, 255, 0.8);
}