a.crt-logo.crt-tag{
    color: #9e9e9e!important;
    font-size: 10px!important;
}
.curator-feed-default-feed-layout{
    margin-top: 10px!important;
}
.crt-post-header{
    padding-top: 10px!important;
}
.crt-post-header .crt-social-icon{
    margin: 0!important;
}
.crt-post .crt-post-text{    
    max-height: 100px!important;
    overflow: hidden;
}

.crt-post-text{
    font-size: 0.9em!important;
}
.crt-post-has-text.crt-post .crt-post-text::after {
    content: '';
    background: linear-gradient(0deg, white 0%, white 50%, transparent 100%);
    width: calc(100% - 2px);
    height: 100px;
    position: absolute;
    bottom: 1px;
    left: 1px;
}
span.crt-post-username {
    display: none;
}
.crt-post-rss .crt-post-text img{
    display:none;
}
.crt-popup .crt-close{
    background-color: rgba(0,0,0,0.5)!important;
    top: 5px!important;
    right: 5px!important;
    border-radius: 100%;
}
.crt-popup .crt-close svg{
    fill: white;
}
.crt-popup-header .crt-social-icon{
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
}
.crt-popup-header .crt-post-userimage{
    margin:0!important;
}
.crt-popup-header .crt-post-name{
    height: 40px!important;
    top: 7px!important;
}