input.searchbar-input.sc-ion-searchbar-md {
  padding-left: 20px;
  border-radius: 20px;
  background: linear-gradient(
    90deg,
    white calc(100% - 50px),
    var(--ion-color-primary) 50px
  );
  padding-right: 85px;
}
ion-icon.searchbar-search-icon.sc-ion-searchbar-md.md.hydrated {
  right: 16px;
  left: unset;
  color: #fff;
}
button.searchbar-clear-button.sc-ion-searchbar-md {
  display: block;
  right: 60px;
}
.locationCard{
  display: flex;
  pointer-events: all;
}
.locationCard .cardImage{
  float: left;
  width: 33%;
  background-size: cover!important;
  background-position: center!important;
  background-repeat: no-repeat!important;
}
.locationCard .cardImage.logo{  
  background-size: contain!important;
}
.locationCard .cardContent{
  float: left;
  width: 75%;
}
.locationCard ion-icon, .productCard  ion-icon, .reviewOverview ion-icon{
  top: 2px;
  position: relative;
}
.productCard .cardImage{
  height: 200px;
  background-size: cover!important;
  background-position: center!important;
  background-repeat: no-repeat!important;
}

