.header{
    background: #e0e0e0;
    text-align: center;
    padding: 20px 0;
}
.header.cover{
    text-align: right;
    padding: 50px 20px; 
    background-size: cover!important;
    background-position: center!important;
}
.header.tags{
    background: #f0f0f0;
}
.header h3{
    margin: 0;
}
.gallerythumb:hover {
    filter: brightness(1.1);
}
.gallerythumb {
    height: 100%;
    margin: 0 5px;
    cursor: pointer;
}
.gallerythumb:nth-child(1){
    margin-left: 0;
}
.gallerythumb:last-child{
    margin-right: 0;
}

.gallerySlider::-webkit-scrollbar-thumb {
    background: #bbb;
}
.gallerySlider::-webkit-scrollbar {
    height: 6px;
}
.gallerySlider {
    height: 70px;
    overflow: scroll hidden;
    padding: 5px 0;
    white-space: nowrap;
    margin-bottom: 10px;
}
.galleryShow {
    width: 100%;
    max-width: 500px;
    display: block;
    margin: auto;
}

.infoH ion-icon {
    position: relative;
    top: 2px;
}
.infoH {
    font-weight: bolder;
    color: #333;
}
.header.reviews{
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #c4c4c4;
}
.addReviewBtn{
    padding: 10px 0;
}
.addReviewBtn ion-button{
    margin: auto;
    display: block;
    width: fit-content;
}
.grey-text{
    color: #aaa!important;
}

#starRatingBox ion-icon{
    width: 40px;
    height: 40px;
    cursor: pointer;
    color: #FF9800;
}.uploadBtn {
    position: relative;
    top: -6px;
    margin-left: 10px;
}.imageRemoveBtn {
    position: absolute;
    top: 0;
    right: 0;
}
.boxedImageSmall {
    height: 40px;
    margin: 0 5px 0 0;
    cursor: pointer;
}

#imgPrev{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.8);
    z-index: 999;
    overflow-y: auto;
}
#imgPrev ion-button{
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
}
#imgPrev img{
    position: absolute;
    top: 50px;
    right: 0;
    width: 100%;
}
.myCommentBtn{
    
}